.custom-form {
  margin: auto;
  padding: 20px;
  background-color: #fff;
}

.custom-form .ant-form-item {
  margin-bottom: 12px;
}

.custom-form .ant-form-item-label > label {
  font-weight: bold;
}

.custom-form .ant-btn {
  width: 100%;
}